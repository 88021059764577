import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { VscListUnordered } from 'react-icons/vsc';
import { FiHome, FiMail } from 'react-icons/fi';
import { MdOutlineTask, MdTaskAlt } from 'react-icons/md';
import {
  IoChatbubbleEllipsesOutline,
  IoReaderOutline,
  IoMailOutline,
  IoMegaphone,
} from 'react-icons/io5';
import { BsCalendar4, BsFolder } from 'react-icons/bs';
import { BiCategoryAlt, BiShow } from 'react-icons/bi';
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiTerminalWindowLine,
  RiContactsLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';

import { Logo } from '../../constant/imagePath';
import useRuntimeData from '../../hooks/useRuntimeData';
import classes from './SideBar.module.css';

const RenderItem = ({ icon, title, subMenu = [], path }) => {
  const active = useLocation()?.pathname;
  const [subnav, setSubnav] = useState(false);
  const subActive = subMenu.find((item, index) => item?.path == active);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <Link
        className={[
          classes?.listItemContainer,
          path == active ? classes?.active : classes?.inactive,
          subActive && classes?.subActive,
          subnav && classes?.marginZero,
        ].join(' ')}
        to={path}
        onClick={(event) => {
          if (subMenu?.length > 0) {
            event.preventDefault();
            showSubnav(!subnav);
          }
        }}
      >
        {icon}
        <span>{title}</span>
        {subMenu?.length > 0 &&
          (subnav ? (
            <RiArrowUpSFill
              size={20}
              color={'white'}
              className={classes?.dropDownIcon}
            />
          ) : (
            <RiArrowDownSFill
              size={20}
              color={'white'}
              className={classes?.dropDownIcon}
            />
          ))}
      </Link>
      {subnav &&
        subMenu.map((item, index) => {
          return (
            <Link
              className={[
                classes?.innerItemContainer,
                item?.path == active && classes?.active,
              ].join(' ')}
              key={index}
              to={item?.path}
            >
              {item?.icon}
              <span>{item.label}</span>
            </Link>
          );
        })}
    </>
  );
};

const SideBar = ({ toggleDrawer, isMobile, isOpen }) => {
  const { unreadCallCount, unreadSMSCount, unreadEmailCount, unreadTodoCount } =
    useRuntimeData();

  const { user: userData, fcmToken, access_token: accessToken } = useSelector(
    (state) => state?.authReducer,
  );
  const isBroker = userData?.role?.includes('broker');
  const isOnlyAdmin = userData?.role?.some((item) => ['admin'].includes(item));
  const isAdmin = !userData?.role?.includes('broker');
  const isOnlySuperAdmin = userData?.role?.includes('super-admin');

  return (
    <div className={classes?.mainContainer}>
      <div className={classes?.logoContainer}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={classes.itemsContainer}>
        <RenderItem
          icon={<FiHome size={20} color={'var(--sidebar-text-color)'} />}
          title={'Home'}
          path={'/'}
        />
        <Box position="relative">
          <RenderItem
            icon={<MdTaskAlt size={22} color={'var(--sidebar-text-color)'} />}
            title={'To-Do'}
            path={'/todo'}
          />
          {unreadTodoCount > 0 ? (
            <Stack
              bgcolor="white"
              borderRadius={9999}
              right={0}
              top={10}
              position="absolute"
              width={20}
              height={20}
              alignItems="center"
              justifyContent="center"
            >
              <Typography component="span" color="primary" fontSize={12}>
                {unreadTodoCount}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
        </Box>
        <Box position="relative">
          <RenderItem
            icon={
              <IoChatbubbleEllipsesOutline
                size={20}
                color={'var(--sidebar-text-color)'}
              />
            }
            title={'Contacts'}
            path={'/conversation'}
          />
          {unreadCallCount + unreadEmailCount + unreadSMSCount > 0 ? (
            <Stack
              bgcolor="white"
              borderRadius={9999}
              right={0}
              top={10}
              position="absolute"
              width={20}
              height={20}
              alignItems="center"
              justifyContent="center"
            >
              <Typography component="span" color="primary" fontSize={12}>
                {unreadCallCount + unreadEmailCount + unreadSMSCount}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
        </Box>
        <RenderItem
          icon={
            <VscListUnordered size={20} color={'var(--sidebar-text-color)'} />
          }
          title={'Listings'}
          path="/listings"
          // subMenu={[
          //   {
          //     label: 'Listings',
          //     path: '/listings',
          //     icon: (
          //       <VscListUnordered
          //         size={20}
          //         color={'var(--sidebar-text-color)'}
          //       />
          //     ),
          //   },
          //   {
          //     label: 'Draft Listings',
          //     path: '/draft-listings',
          //     icon: (
          //       <RiDraftLine size={20} color={'var(--sidebar-text-color)'} />
          //     ),
          //   },
          //   {
          //     label: 'Lead Listings',
          //     path: '/lead-listings',
          //     icon: (
          //       <RiTerminalWindowLine size={20} color={'var(--sidebar-text-color)'} />
          //     ),
          //   },
          // ]}
        />
        <RenderItem
          icon={<BsFolder size={20} color={'var(--sidebar-text-color)'} />}
          title={'Data Rooms'}
          path={'/team-folder'}
        />
        <RenderItem
          icon={
            <HiOutlineClipboardList
              size={20}
              color={'var(--sidebar-text-color)'}
            />
          }
          title={'Interests'}
          path={'/interests'}
        />
        <RenderItem
          icon={<FiMail size={18} color={'var(--sidebar-text-color)'} />}
          title={'Projects'}
          path={'/task-manager'}
        />
        <RenderItem
          icon={<MdOutlineTask size={22} color={'var(--sidebar-text-color)'} />}
          title={'Tasks'}
          path={'/tasks'}
        />
        <RenderItem
          icon={<BsCalendar4 size={18} color={'var(--sidebar-text-color)'} />}
          title={'Task Templates'}
          path={'/all-template'}
        />
        <RenderItem
          icon={<IoMailOutline size={18} color={'var(--sidebar-text-color)'} />}
          title={'Messages'}
          path={'/messages'}
        />
        {/* <RenderItem
          icon={<IoChatbubbleEllipsesOutline size={20} color={"var(--sidebar-text-color)"} />}
          title={"Conversation"}
          path={"/conversation"}
        /> */}

        {/*!isBroker && (
          <>
            <RenderItem
              icon={
                <MdOutlineFeaturedPlayList
                  size={20}
                  color={"var(--sidebar-text-color)"}
                />
              }
              title={"Website Forms"}
              subMenu={[
                {
                  label: "Contact Us",
                  path: "/contact-us",
                  icon: (
                    <MdOutlineContacts
                      size={18}
                      color={"var(--sidebar-text-color)"}
                    />
                  ),
                },
                {
                  label: "Valuation",
                  path: "/free-evaluation",
                  icon: (
                    <CgUserList size={18} color={"var(--sidebar-text-color)"} />
                  ),
                },
                // {
                //   label: "Preferred Business",
                //   path: "/preferred-business",
                //   icon: (
                //     <TiPrinter size={18} color={"var(--sidebar-text-color)"} />
                //   ),
                // },
              ]}
            />
            { {isAdmin && (
              <RenderItem
                icon={
                  <HiOutlineUserGroup
                    size={20}
                    color={"var(--sidebar-text-color)"}
                  />
                }
                title={"Contacts"}
                subMenu={[
                  {
                    label: "Brokers",
                    path: "/all-broker",
                    icon: (
                      <FiUsers size={18} color={"var(--sidebar-text-color)"} />
                    ),
                  },
                  {
                    label: "Buyer/Seller",
                    path: "/all-user",
                    icon: (
                      <FiUsers size={18} color={"var(--sidebar-text-color)"} />
                    ),
                  },
                  {
                    label: "Admin Users",
                    path: "/admin-users",
                    icon: (
                      <FiUsers size={18} color={"var(--sidebar-text-color)"} />
                    ),
                  },
                  {
                    label: "Outside Users",
                    path: "/outside-users",
                    icon: (
                      <FiUsers size={18} color={"var(--sidebar-text-color)"} />
                    ),
                  },
                ]}
              />
            )} }
          </>
        )*/}

        {/* {isBroker && (
          <RenderItem
            icon={
              <HiOutlineUserGroup
                size={20}
                color={"var(--sidebar-text-color)"}
              />
            }
            title={"Contacts"}
            subMenu={[
              {
                label: "Buyer/Seller",
                path: "/all-user",
                icon: <FiUsers size={18} color={"var(--sidebar-text-color)"} />,
              },

              {
                label: "Outside Users",
                path: "/outside-users",
                icon: <FiUsers size={18} color={"var(--sidebar-text-color)"} />,
              },
              {
                label: "Brokers",
                path: "/all-broker",
                icon: <FiUsers size={18} color={"var(--sidebar-text-color)"} />,
              },
              {
                label: "Admin Users",
                path: "/admin-users",
                icon: <FiUsers size={18} color={"var(--sidebar-text-color)"} />,
              },
            ]}
          />
        )} */}

        {/* <RenderItem
          icon={<CgTemplate size={20} color={"var(--sidebar-text-color)"} />}
          title={"Email Templates"}
          subMenu={[
            {
              label: "Emails",
              path: "/email-templates",
              icon: (
                <AiOutlineMail size={18} color={"var(--sidebar-text-color)"} />
              ),
            },
          ]}
        /> */}

        {/* <RenderItem
          icon={
            <VscListUnordered size={20} color={"var(--sidebar-text-color)"} />
          }
          title={"Listings"}
          path={"/listings"}
        /> */}

        {/* {!isBroker && (
          <RenderItem
            icon={<MdGroups size={20} color={"var(--sidebar-text-color)"} />}
            title={"Groups"}
            path={"/groups"}
          />
        )} */}
        {userData?.role?.some((item) => 'admin' == item) && (
          <RenderItem
            icon={<IoMegaphone size={18} color={'var(--sidebar-text-color)'} />}
            title={'Marketing'}
            path={'/market'}
          />
        )}
        {/* <RenderItem
          icon={<VscGraph size={20} color={'var(--sidebar-text-color)'} />}
          title={'Reports'}
          path={'/reports'}
        /> */}

        {/*userData?.role?.some((item) =>
          ["admin"].includes(item)
        ) && (
            <RenderItem
              icon={
                <BiCategoryAlt size={20} color={"var(--sidebar-text-color)"} />
              }
              title={"CMS"}
              subMenu={[
                // {
                //   label: "Home",
                //   path: "/cms/home",
                //   icon: (
                //     <AiOutlineHome
                //       size={18}
                //       color={"var(--sidebar-text-color)"}
                //     />
                //   ),
                // },
                // {
                //   label: "Contact Us",
                //   path: "/cms/contact-us",
                //   icon: (
                //     <BiMessageDots
                //       size={18}
                //       color={"var(--sidebar-text-color)"}
                //     />
                //   ),
                // },
                // {
                //   label: "Services",
                //   path: "/cms/services",
                //   icon: (
                //     <MdMiscellaneousServices
                //       size={18}
                //       color={"var(--sidebar-text-color)"}
                //     />
                //   ),
                // },
                // {
                //   label: "About Us",
                //   path: "/cms/about",
                //   icon: <FiInfo size={18} color={"var(--sidebar-text-color)"} />,
                // },
                // {
                //   label: "Careers",
                //   path: "/cms/careers",
                //   icon: (
                //     <IoBriefcaseOutline
                //       size={18}
                //       color={"var(--sidebar-text-color)"}
                //     />
                //   ),
                // },
                {
                  label: "Buy A Business",
                  path: "/cms/buy-a-business",
                  icon: <BsCart2 size={18} color={"var(--sidebar-text-color)"} />,
                },
                // {
                //   label: "Sell your Business",
                //   path: "/cms/sell-your-business",
                //   icon: (
                //     <MdOutlineSell
                //       size={18}
                //       color={"var(--sidebar-text-color)"}
                //     />
                //   ),
                // },
                // {
                //   label: "Footer",
                //   path: "/cms/footer",
                //   icon: (
                //     <MdOutlineAssignment
                //       size={18}
                //       color={"var(--sidebar-text-color)"}
                //     />
                //   ),
                // },
              ]}
            />
          )*/}

        {/* Cruds */}
        {isOnlySuperAdmin && (
          <RenderItem
            icon={
              <IoReaderOutline size={20} color={'var(--sidebar-text-color)'} />
            }
            title={'CRUDS'}
            path="#"
            subMenu={[
              // {
              //   label: "Services",
              //   path: "/services",
              //   icon: (
              //     <MdMiscellaneousServices
              //       size={18}
              //       color={"var(--sidebar-text-color)"}
              //     />
              //   ),
              // },
              // {
              //   label: "Faqs",
              //   path: "/faqs",
              //   icon: (
              //     <AiOutlineQuestionCircle
              //       size={18}
              //       color={"var(--sidebar-text-color)"}
              //     />
              //   ),
              // },
              {
                label: 'Categories',
                path: '/categories',
                icon: (
                  <BiCategoryAlt
                    size={18}
                    color={'var(--sidebar-text-color)'}
                  />
                ),
              },
              {
                label: 'Status',
                path: '/status',
                icon: (
                  <BiCategoryAlt
                    size={18}
                    color={'var(--sidebar-text-color)'}
                  />
                ),
              },
              {
                label: "User Type",
                path: "/user-type",
                icon: (
                  <BiCategoryAlt size={18} color={'var(--sidebar-text-color)'} />
                ),
              },
              // {
              //   label: "Core Values",
              //   path: "/core-values",
              //   icon: (
              //     <GiAtomCore size={18} color={"var(--sidebar-text-color)"} />
              //   ),
              // },
              // {
              //   label: "Our Team",
              //   path: "/our-team",
              //   icon: (
              //     <RiTeamLine size={18} color={"var(--sidebar-text-color)"} />
              //   ),
              // },
              // {
              //   label: "Reviews",
              //   path: "/reviews",
              //   icon: (
              //     <BiCommentDetail
              //       size={18}
              //       color={"var(--sidebar-text-color)"}
              //     />
              //   ),
              // },
            ]}
          />
        )}
        {/* {!isBroker && (
          <RenderItem
            icon={<BiChat size={20} color={'var(--sidebar-text-color)'} />}
            title={'Support Chat'}
            path={'/support-chat'}
          />
        )} */}
        {isOnlySuperAdmin && (
          <RenderItem
            icon={
              <RiContactsLine size={20} color={'var(--sidebar-text-color)'} />
            }
            title={'Contact Management'}
            path={'/contact-management'}
          />
        )} 
        {(isOnlySuperAdmin || isBroker) && <RenderItem
          icon={
            <RiMoneyDollarCircleLine size={20} color={'var(--sidebar-text-color)'} />
          }
          title={'Commission'}
          path={'/commission'}
        />}
        {(userData?.email.toLowerCase() === 'adam@denverbbs.com') ? (
          <RenderItem
            icon={<RiTerminalWindowLine size={20} color={"var(--sidebar-text-color)"} />}
            title={"Token Management"}
            path={"/token-management"}
          />
        ) : (
          <></>
        )}
        {/* <RenderItem
          icon={
            <RiBuildingLine size={20} color={'var(--sidebar-text-color)'} />
          }
          title={'Company'}
          path={'/company'}
        /> */}
        {/*isOnlySuperAdmin ? (
          <RenderItem
            icon={<AiOutlineUserAdd size={20} color={"var(--sidebar-text-color)"} />}
            title={"Manage Admins"}
            path={"/manage-admins"}
          />
        ) : (
          <></>
        )*/}
      </div>
    </div>
  );
};

export default SideBar;
