import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { enUS } from '@mui/x-date-pickers/locales';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Close, Padding } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TimePicker from 'react-bootstrap-time-picker';
import { Col, Row } from 'react-bootstrap';
import { MdCircle } from 'react-icons/md';
import 'dayjs/locale/en';

import {
  todoImportanceOptions,
  todoTypeOptions,
} from '../../../constant/commonData';
import { apiHeader, BaseURL } from '../../../config/apiUrl';
import { Get, Post } from '../../../Axios/AxiosFunctions';
import dayjs from '../../../utils/timeUtils';
import { toast } from 'react-toastify';

export default function AddTodoDialog({
  open,
  setOpen,
  allAdmins,
  onSuccess = () => {},
}) {
  const { access_token: accessToken, user } = useSelector(
    (state) => state?.authReducer,
  );

  const [loading, setLoading] = useState(false);
  const [assignee, setAssignee] = useState(null);
  const [type, setType] = useState(todoTypeOptions[0]?.value);
  const [note, setNote] = useState('');
  const [importance, setImportance] = useState(todoImportanceOptions[0]?.value);
  const [deadline, setDeadline] = useState(null);
  const [milliseconds, setMilliseconds] = useState(null);

  const getTimeSeconds = (time) => {
    const m = time.get('minute');
    const h = time.get('hour');
    return Math.floor((h * 3600 + m * 60) / 1800) * 1800;
  };

  const handleMilliseconds = (ms) => {
    let ddlInDate = dayjs(deadline);
    if (!ddlInDate.isValid()) {
      console.log('Date format is invalid');
      return;
    }
    ddlInDate = ddlInDate
      .set('hour', Math.floor(ms / 3600))
      .set('minute', Math.floor((ms % 3600) / 60));
    setDeadline(ddlInDate);
    setMilliseconds(getTimeSeconds(ddlInDate));
  };

  const onSubmit = async () => {
    const url = BaseURL('todo');
    const body = {
      assignee,
      note,
      type,
      importance,
      dueDate: dayjs(deadline)?.toDate(),
    };

    setLoading(true);
    const res = await Post(url, body, apiHeader(accessToken));
    setLoading(false);
    if (res) {
      toast.info('Successfully created');
      onSuccess();
      onClose();
      return;
    }
    toast.info('Creation is failed');
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setDeadline(dayjs());
    setMilliseconds(3600 * 17);
    setAssignee(user?._id);
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="en"
      localeText={
        enUS.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
          }}
        >
          Add To-Do
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Row className="gy-3 mt-1">
            <Col lg={12}>
              <TextField
                multiline
                minRows={5}
                label="Task Note"
                name="note"
                value={note}
                onChange={(e) => setNote(e?.target?.value)}
                fullWidth
                autoFocus
              />
            </Col>

            <Col md={4}>
              <TextField
                name="assignee"
                label="Assignee *"
                value={assignee}
                onChange={(e) => setAssignee(e?.target?.value)}
                select
                fullWidth
              >
                {allAdmins.map((ele) => (
                  <MenuItem key={ele?._id} value={ele?._id}>
                    {`${ele?.firstName} ${ele?.lastName}`}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md={4}>
              <TextField
                name="type"
                label="Type *"
                value={type}
                onChange={(e) => setType(e?.target?.value)}
                select
                fullWidth
              >
                {todoTypeOptions.map((ele) => (
                  <MenuItem key={ele?.value} value={ele?.value}>
                    {ele?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col lg={4}>
              <TextField
                name="importance"
                label="Importance *"
                value={importance}
                onChange={(e) => setImportance(e?.target?.value)}
                select
                fullWidth
              >
                {todoImportanceOptions.map((ele) => (
                  <MenuItem key={ele?.value} value={ele?.value}>
                    <MdCircle
                      size={25}
                      style={{
                        color: ele?.value == 'lemon' ? 'gold' : ele?.value,
                        marginRight: '10px',
                      }}
                    />
                    {ele?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Col>

            <Col md={6}>
              <DatePicker
                label={'Due Date *'}
                value={deadline}
                onChange={(e) => setDeadline(e)}
              />
            </Col>
            <Col md={6}>
              <TimePicker
                style={{
                  height: '3.5em',
                  padding: '1em',
                  font: 'inherit',
                  boxShadow: '0px 0 5px 2px #0000000d',
                  border: 'none',
                }}
                value={milliseconds}
                onChange={(e) => handleMilliseconds(e)}
              />
            </Col>
          </Row>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onSubmit} disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
